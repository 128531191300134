








import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      oss: "oss"
    })
  },
  mounted() {
    {
      pdfurl: this.oss +
        "pdf/IceRing63RTUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf";
    }
  }
});
